const monthAgo = new Date();
monthAgo.setDate(monthAgo.getDate() - 30);

/* eslint-disable */
export const RAILS_ENV = process.env.RAILS_ENV;
export const CACHING_VERY_VERY_LOW = process.env.CACHING_VERY_VERY_LOW || '5';
export const CACHING_VERY_LOW = process.env.CACHING_VERY_LOW || '30';
export const CACHING_LOW = process.env.CACHING_LOW || '300';
export const CACHING_MEDIUM = process.env.CACHING_MEDIUM || '600';
export const CACHING_HIGH = process.env.CACHING_HIGH || '3600';
export const CACHING_VERY_HIGH = process.env.CACHING_VERY_HIGH || '28800';
export const CACHING_VERY_VERY_HIGH = process.env.CACHING_VERY_VERY_HIGH || '259200';
export const CACHING_ETORO_NEWS_LOW = process.env.CACHING_ETORO_NEWS_LOW || '1800';
export const CACHING_ETORO_NON_NEWS_LOW = process.env.CACHING_ETORO_NON_NEWS_LOW || '28800';
export const CACHING_ETORO_NEWS_HIGH = process.env.CACHING_ETORO_NEWS_HIGH || '86400';
export const CACHING_ETORO_NEWS_VERY_HIGH = process.env.CACHING_ETORO_NEWS_VERY_HIGH || '604800';
export const ETORO_STORIES_MIN_CF_SCORE = process.env.ETORO_STORIES_MIN_CF_SCORE || '15';
export const CF_NLU2_LANGS = process.env.CF_NLU2_LANGS || 'en,ru,uk,ro,cs,sl,sk,cnr,bs,bg,be,pl,mk,sr,hr,lv,lt,es,fr,de,pt,it,zh';
export const LISTINGS_DEFAULT_MIN_CONFIDENCE = process.env.LISTINGS_DEFAULT_MIN_CONFIDENCE || '0.4';
export const EARLIEST_SEARCH_DATE_GOLD_USER = monthAgo.toISOString().slice(0, 10);
export const CF_URL_FOR_FINPROMPT = RAILS_ENV !== 'production' ? 'https://staging-server.cityfalcon.com' : 'https://www.cityfalcon.ai'
/* eslint-enable */
