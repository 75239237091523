import moment from 'moment';
import { periodValues } from '../data/filters/storyFilters';
import {
  PAST_MAX_DATE_ERR, FUTURE_MAX_DATE_ERR, DATE_FORMAT_ERR, MAX_DATE,
  EARLIEST_DATE_RANGE_ERR, EARLIEST_DAY_ERROR, EARLIEST_MIN_PERIOD_ERR,
  FILINGS_MAX_DATE, MAX_SPECIFIC_DAYS_SELECTION,
} from '../data/filters/filterConstants';
import { notAllowedTimeFilters } from '../data/directory/constants';
import { EARLIEST_SEARCH_DATE_GOLD_USER } from '../data/environment';

moment.locale('en-GB');

export const validateMaxPeriodDate = (time, periodValue, maxPeriodValues, earliestSearchDate) => {
  const currentDate = moment().subtract(time, periodValue);

  if (time > maxPeriodValues[periodValue]) return PAST_MAX_DATE_ERR;
  if (moment(earliestSearchDate) > currentDate) return EARLIEST_MIN_PERIOD_ERR;
};

// check if date and time is the same in start/end dates
export const validateCurrentDayDate = (startDate, endDate) => moment(startDate).isSame(endDate);

// validate earliest search date depending on user
export const validateEarliestSearchDate = (
  earliestDate, startDate, endDate, period, isFilingsPage, gold,
) => {
  const earliest = moment(earliestDate);
  const start = moment(startDate).utc().format();

  // do not allow to select date that are before earliest search date
  if (
    ((earliest.utc().format() > start) && period && !gold)
    || (
      (earliest.utc().format() > start)
      && period
      && start > moment(EARLIEST_SEARCH_DATE_GOLD_USER).utc().format()
      && gold
    )
  ) return EARLIEST_MIN_PERIOD_ERR;
  // check if user's date is not less then max past date
  if (
    (!isFilingsPage && MAX_DATE > start)
    || (isFilingsPage && FILINGS_MAX_DATE > start)
  ) return PAST_MAX_DATE_ERR;
  // if earliest date if bigger than end date in calendar
  if (earliest > moment(endDate).utc().format() && !period) return EARLIEST_DATE_RANGE_ERR;
  // validate if difference between earliest/current more then 24h
  if (earliest.diff(start, 'hours') > 24 && !moment(start).isAfter(earliest)) return EARLIEST_DAY_ERROR;
};

// date validation(for max past and future date) from custom input
export const validateDateValue = (dateValue, isFilingsPage) => {
  const validDate = moment(dateValue, 'DD-MM-YYYY', true).isValid();

  if (!validDate) return DATE_FORMAT_ERR;

  const [day, month, year] = dateValue.split('-');
  const currentDate = moment([year, month - 1, day]).utc();

  const dateNow = moment().utc();

  if (
    (!isFilingsPage && MAX_DATE > currentDate)
    || (isFilingsPage && FILINGS_MAX_DATE > currentDate)
  ) return PAST_MAX_DATE_ERR;
  if (currentDate > dateNow) return FUTURE_MAX_DATE_ERR;

  return currentDate.toDate();
};

// date format validation for custom input
export const dateFilterInputValue = (dateValue) => {
  const validDate = moment(dateValue, 'DD-MM-YYYY', true).isValid();

  if (validDate) {
    const [day, month, year] = dateValue.split('-');

    // return Date object for datepicker
    return new Date(year, month - 1, day);
  }
};

export const formatDateTime = (date, time) => {
  const [hours, minutes] = time.split(':');

  return moment(date).set('h', hours).set('m', minutes).seconds(0)
    .milliseconds(0)
    .toDate();
};

export const validateDatesRanges = (startDate, endDate) => {
  const start = moment(startDate).utc().format();
  const end = moment(endDate).utc().format();

  if (moment(end).isBefore(start)) return false;
  if (moment(start).isAfter(end)) return false;
  return true;
};

export const showTimeFilterValue = ({
  selected_time_filter: selectedTimeFilter,
  time_period: timePeriod,
  time_filter: timeFilter,
  start_datetime: startDatetime,
  end_datetime: endDatetime,
}) => {
  if (selectedTimeFilter === 'time_period') {
    const [periodValue, periodName] = timePeriod.split(' ');
    return `${periodValue} ${periodName}`;
  }
  if (selectedTimeFilter === 'custom') {
    if (window.innerWidth <= 810 || window.innerWidth >= 1040) {
      return `${moment(startDatetime).format('DD-MM-YYYY HH:mm')} to 
              ${moment(endDatetime).format('DD-MM-YYYY HH:mm')}`;
    }
    return `${moment(startDatetime).format('DD-MM-YYYY').substring(0, 6)}...`;
  }

  if (notAllowedTimeFilters.includes(timeFilter)) return '1 month';

  const [{ name }] = periodValues.filter(({ value }) => value === timeFilter);
  return name;
};

export const filterSelectedTimeFilter = (selectedTimeFilter) => {
  if (selectedTimeFilter === 'time_period') return ['time_period'];
  if (selectedTimeFilter === 'custom') return ['start_datetime', 'end_datetime'];
  return ['time_filter'];
};

export const validateMaxDaysRange = (period, newDate, prevStartDate, prevEndDate) => {
  if (period === 'startDate') {
    return moment(newDate).add(MAX_SPECIFIC_DAYS_SELECTION, 'days').isSameOrAfter(moment(prevEndDate));
  }
  if (period === 'endDate') {
    return moment(newDate).subtract(MAX_SPECIFIC_DAYS_SELECTION, 'days').isSameOrBefore(moment(prevStartDate));
  }
};
