import moment from 'moment';
import isEqual from 'lodash-es/isEqual';
import isEmpty from 'lodash-es/isEmpty';

import 'intersection-observer';
import { queryItemWatchlistParser, generateQuery } from './watchlistHelperFunctions';
import { copy } from './copy';
import { getUserToken } from './getUserToken';

import StorageSvc from '../services/StorageSvc';

import {
  initialState,
  languagesCodes,
  orderValues,
  periodValues,
  refreshRateConversion,
  refreshValues,
  refreshValuesConversion,
  sentimentValues,
  sourceValues,
} from '../data/filters/storyFilters';
import { SILVER, TWEETS } from '../data/permissions';
import { CF_NLU2_LANGS } from '../data/environment';

// helper method for parsing registered user wl topics into more stories query request
export const watchlistTopicsQueryParser = (topics) => {
  const query = topics.map((item) => {
    if (!item.query && item.name) return `"${item.name}"`;
    if (item.entity_type === 'Query') {
      let quotedQuery = '';

      if (/\b(?:AND|OR|NOT)\b/g.test(item.query || item.name)) {
        quotedQuery = item.query || generateQuery(item.name);
      } else if ((item.query || item.name).match(/\\"/)) {
        quotedQuery = (item.query || `"${item.name}"`);
      } else {
        quotedQuery = (item.query || item.name).replace(/"/g, '\\"');
      }
      return quotedQuery;
    }

    return item.query;
  });
  return `${query.join(' OR ')}`;
};

// create query from users watchlist topics for fetching stories(get /v1/stories)
export const watchlistTopicsQueryCreator = (topics, token) => {
  const selectedWlTopics = topics.filter((topic) => !topic.hidden);

  // for registered users more stories request
  if (token) return watchlistTopicsQueryParser(selectedWlTopics);

  const query = selectedWlTopics.map((item) => {
    if (!item.groups) {
      let quotedQuery = '';

      if (/\b(?:AND|OR|NOT)\b/g.test(item.query || item.name)) {
        quotedQuery = generateQuery(item.query || item.name);
      } else {
        quotedQuery = `"${(item.query || item.name).replace(/"/g, '\\"')}"`;
      }
      return quotedQuery;
    }

    const [{ groupName, groupType }] = item.groups;

    return `"group:${groupType}:${groupName}:${(item.query)}"`;
  });

  return query.join(' OR ');
};

// parse single topic to create search query
export const getTopicGroup = (item) => {
  try {
    // it topic doesn't have groups, it is custom query or a topic class
    if (!item.groups) {
      if (item?.itemType === 'AssetClass') {
        return `"group:AssetClass:${item.id}:${item.name}"`;
      }
      // check if word is inside single quotes
      if (item.match(/^'(.*?)'$/)) {
        // Leave first and last single quote and replace other with the escape sign
        return item.replace(/(?!^')'(?!$)/g, "\\'");
      }
      // check if query has logical AND OR, if so add quotes to words except logical keywords
      if (/\b(?:AND|OR|NOT)\b/g.test(item)) {
        return generateQuery(item);
      }
      // Check if word is inside of double quotes
      if (item.match(/^"(.*?)"$/g)) {
        return item.replace(/"/g, "'").replace(/(?!^')'(?!$)/g, "\\'");
      }
      // if query doesn't have logical operators, return it
      return `"${item.replace(/\((.*?)\)/g, '\\($1\\)').replace(/"|'/g, "\\'")}"`;
    }

    const [{ groupName, groupType }] = item.groups;

    let itemName = item.name;
    // check if word is inside single quotes
    if (item.name.match(/^'(.*?)'$/)) {
      // Leave first and last single quote and replace other with the escape sign
      itemName = item.name.replace(/(?!^')'(?!$)/g, "\\'");
    } else if (/\b(?:AND|OR|NOT)\b/g.test(item.name)) {
      // check if query has logical AND OR, if so add quotes to words except logical keywords
      itemName = generateQuery(item.name);
    } else if (item.name.match(/^"(.*?)"$/g)) {
      // Check if word is inside of double quotes
      itemName = item.name.replace(/"/g, "'").replace(/(?!^')'(?!$)/g, "\\'");
    } else {
      // if query doesn't have logical operators, return it
      itemName = `${item.name.replace(/\((.*?)\)/g, '\\($1\\)').replace(/"|'/g, "\\'")}`;
    }

    return `"group:${groupType}:${groupName}:${itemName}"`;
  } catch (err) {
    return undefined;
  }
};

export const getAssetClassGroup = (item) => {
  const { id, name } = item;
  return `"group:AssetClass:${id}:${name}"`;
};

// check if all watchlist topics are selected
export const checkAllAssetsStatus = (topics) => (
  topics.length ? topics.every((topic) => !topic.hidden) : false
);

export const getFormattedQuery = (query) => String(query || '').trim();

export const piwikWatchlistTopicsQueryFormatter = (entityType, topic) => {
  const { name } = topic.query ? queryItemWatchlistParser(topic.query) : topic;

  return `${entityType}:${name}`;
};

export const piwikSearchbarTopicsFormatter = (topic, inputValue) => {
  if (Array.isArray(topic)) {
    const query = topic.map((item) => {
      if (item.groups && item.groups[0]) return `${item.groups[0].groupType}:${item.name}`;
      if (item.itemType === 'AssetClass') return `AssetClass:${item.name}`;
      return item.name;
    });

    // for returning user custom query
    if (inputValue) query.push(inputValue);

    return query.join(', ');
  }

  const { groupType = null } = (topic.groups && topic.groups[0]) || {};

  return groupType ? `${groupType}:${topic.name}` : topic.name;
};

export function calculateTimeAgo(time, short = false, isFilings = false) {
  const relativeTime = {
    s: (number) => (number > 1 ? `${number} seconds ago` : `${number} second ago`),
    ss: '%d seconds ago',
    m: '1 minute ago',
    mm: '%d minutes ago',
    h: '1 hour ago',
    hh: '%d hours ago',
    d: '1 day ago',
    dd: '%d days ago',
    M: '1 month ago',
    MM: '%d months ago',
    y: '1 year ago',
    yy: '%d years ago',
  };

  if (short) {
    Object.assign(relativeTime, {
      s: '%d s ago',
      ss: '%d s ago',
      m: '1 min ago',
      mm: '%d min ago',
      h: '1 hr ago',
      hh: '%d hr ago',
      d: '1 d ago',
      dd: '%d d ago',
      M: '1 mth ago',
      MM: '%d mth ago',
      y: '1 yr ago',
      yy: '%d yr ago',
    });
  }

  if (moment().diff(moment(time), 'hours') <= 24 && isFilings) {
    return 'Today';
  }

  return moment(time).fromNow(
    moment.updateLocale('en', {
      relativeTime,
    }),
  );
}

export function roundTo(value, round = 2) {
  return (
    typeof value === 'number' && (Math.trunc(value) === value || String(value).split('.').pop().length > round) ? (
      value.toFixed(round)
    ) : (
      value
    )
  );
}

// parse queries from url
export const getQueryStringParams = (query) => (
  query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
      .split('&')
      .reduce((params, param) => {
        const [key, value] = param.split('=');
        return {
          ...params,
          [key]: value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '',
        };
      }, {})
    : {}
);

export function getMetaTag(key, value) {
  let tag = window.document.querySelector(`meta[${key}="${value}"]`);
  if (!tag) {
    tag = window.document.createElement('meta');
    tag.setAttribute(key, value);
    window.document.head.appendChild(tag);
  }
  return tag;
}

export function compareFilters(oldFilters, newFilters) {
  const oldFiltersCopy = copy(oldFilters);
  const newFiltersCopy = copy(newFilters);

  [
    'expand_stories', 'show_similar_stories_in_feed', 'summarization_enabled',
    'user_newsfeed_view_web', 'user_newsfeed_view_description_web',
    'user_newsfeed_view_images_web', 'user_newsfeed_advanced_view_web',
    'user_newsfeed_compactview_web', 'translationSupportedLanguages',
    'user_newsfeed_view_images', 'user_newsfeed_advanced_view',
    'user_newsfeed_compactview',
  ].forEach((filter) => {
    delete oldFiltersCopy[filter];
    delete newFiltersCopy[filter];
  });

  return isEqual(oldFiltersCopy, newFiltersCopy);
}

export function compareResetFilters(oldFilters, newFilters) {
  const oldFiltersCopy = copy(oldFilters);
  const newFiltersCopy = copy(newFilters);

  return isEqual(oldFiltersCopy, newFiltersCopy);
}

export function convertDate(date) {
  return date.replace(' ', 'T').replace(' ', 'Z').replace(/\+.+/, '');
}

export const addIds = (list) => (
  list.map((item, i) => ({
    id: i,
    ...item,
  }))
);

export const compareUserViewFilters = (oldFilters, newFilters) => {
  const oldFiltersCopy = {};
  const newFiltersCopy = {};

  [
    'expand_stories', 'show_similar_stories_in_feed', 'summarization_enabled',
    'user_newsfeed_view_web', 'user_newsfeed_view_description_web',
    'user_newsfeed_view_images_web', 'user_newsfeed_advanced_view_web',
    'user_newsfeed_compactview_web',
  ].forEach((filter) => {
    oldFiltersCopy[filter] = oldFilters[filter];
    newFiltersCopy[filter] = newFilters[filter];
  });

  return isEqual(oldFiltersCopy, newFiltersCopy);
};

export const getDifferentResetFilters = (resetFiltersValues, newFiltersValues, accessLevels) => {
  let differentFilterValues = [];
  let filterIds = [
    'refresh_rate', 'order_by', 'time_filter', 'categories', 'twitter_following_only', 'with_links', 'min_score', 'languages', 'user_newsfeed_advanced_view_web', 'user_newsfeed_advanced_view', 'show_similar_stories_in_feed', 'group_similar_stories', 'selected_time_filter', 'summarization_enabled', 'expand_stories', 'user_newsfeed_view_images_web', 'user_newsfeed_view_description_web', 'user_newsfeed_view_web', 'user_newsfeed_view', 'user_newsfeed_compactview_web', 'user_newsfeed_compactview', 'paywall', 'registrationRequired', 'premium_content_only', 'isPremiumLabelsEnabled',
  ];
  if (getUserToken()) {
    if (accessLevels.includes(SILVER)) {
      filterIds = [...filterIds, 'sentiment_categories', 'sentiment_filter', 'sentiment_down_limit', 'sentiment_up_limit'];
    }
    filterIds.forEach((filter) => {
      if (filter === 'languages') {
        if (!isEqual(resetFiltersValues[filter]?.split(',')?.sort(), newFiltersValues?.[filter]?.split(',')?.sort())) {
          differentFilterValues.push(filter);
        }
      } else if (filter === 'categories') {
        if (!isEqual(resetFiltersValues[filter]?.split(',')?.sort(), newFiltersValues?.[filter]?.split(',')?.sort())) {
          const isShowMajorSources = JSON.parse(StorageSvc.getItem('ShowMajorSources'))?.status;
          if (isShowMajorSources) {
            differentFilterValues.push('major_sources_only');
          }
          if (!newFiltersValues?.[filter]?.includes('mp')
            || !newFiltersValues?.[filter]?.includes('r')
            || !newFiltersValues?.[filter]?.includes('c')
            || !newFiltersValues?.[filter]?.includes('i')
            || !newFiltersValues?.[filter]?.includes('e')) {
            differentFilterValues.push(filter);
          }
        }
      } else if (filter === 'sentiment_categories') {
        if (!isEqual(resetFiltersValues[filter]?.sort(), newFiltersValues?.[filter]?.sort())) {
          differentFilterValues.push(filter);
        }
      } else if (!isEqual(resetFiltersValues[filter], newFiltersValues?.[filter])) {
        differentFilterValues.push(filter);
      }
      if (differentFilterValues.includes('selected_time_filter')) {
        differentFilterValues = differentFilterValues.filter((fValue) => fValue !== 'time_filter');
      }
      if (!differentFilterValues.includes('sentiment_filter')) {
        differentFilterValues = differentFilterValues.filter((fValue) => {
          if (fValue === 'sentiment_down_limit' || fValue === 'sentiment_up_limit') {
            return false;
          }
          return true;
        });
      }
    });
  } else {
    filterIds.forEach((filter) => {
      if (filter === 'languages') {
        if (!isEqual(resetFiltersValues[filter]?.split(',')?.sort(), newFiltersValues?.[filter]?.split(',')?.sort())) {
          differentFilterValues.push(filter);
        }
      } else if (filter === 'categories') {
        if (!isEqual(resetFiltersValues[filter]?.split(',')?.sort(), newFiltersValues?.[filter]?.split(',')?.sort())) {
          const isShowMajorSources = JSON.parse(StorageSvc.getItem('ShowMajorSources'))?.status;
          if (isShowMajorSources) {
            differentFilterValues.push('major_sources_only');
          }
          if (!newFiltersValues?.[filter]?.includes('mp')
            || !newFiltersValues?.[filter]?.includes('r')) {
            differentFilterValues.push(filter);
          }
        }
      } else if (!isEqual(resetFiltersValues[filter], newFiltersValues?.[filter])) {
        differentFilterValues.push(filter);
      }
      if (differentFilterValues.includes('selected_time_filter')) {
        differentFilterValues = differentFilterValues.filter((fValue) => fValue !== 'time_filter');
      }
    });
  }
  return differentFilterValues;
};

export const getFormattedDataToPreferencesEndpoint = ({
  user_newsfeed_compactview_web: initialCompactView, ...state
}) => ({
  refresh_rate: refreshRateConversion[state.refresh_rate],
  order_by: state.order_by,
  time_filter: state.time_filter,
  categories: 'mp,op,r',
  twitter_following_only: state.twitter_following_only,
  with_links: state.with_links,
  min_score: state.min_score,
  languages: [
    ...new Set([
      'en',
      typeof window === 'object' ? window.navigator.language.split('-')[0] : 'en',
    ]),
  ].join(','),
  all_languages: state.all_languages,
  user_newsfeed_advanced_view_web: state.user_newsfeed_advanced_view_web,
  user_newsfeed_advanced_view: state.user_newsfeed_advanced_view,
  user_newsfeed_view_web: state.user_newsfeed_view_web,
  user_newsfeed_view: state.user_newsfeed_view,
  tiles_view: state.tiles_view,
  show_similar_stories_in_feed: state.show_similar_stories_in_feed,
  group_similar_stories: state.group_similar_stories,
  selected_time_filter: state.selected_time_filter,
  summarization_enabled: state.summarization_enabled,
  expand_stories: state.expand_stories,
  user_newsfeed_view_images_web: state.user_newsfeed_view_images_web,
  user_newsfeed_view_description_web:
    state.user_newsfeed_view_description_web,
  user_newsfeed_compactview_web: initialCompactView,
  user_newsfeed_compactview: initialCompactView,
});

export const getResetValuesForFilters = (accessLevels, compactView) => {
  const data = getFormattedDataToPreferencesEndpoint(initialState, compactView);
  if (!accessLevels.includes(TWEETS)) {
    data.categories = data.categories.split(',').filter((cat) => !['c', 'i', 'e'].includes(cat)).join(',');
  }

  data.languages = [
    ...new Set([
      'en',
      typeof window === 'object' ? window.navigator.language.split('-')[0] : 'en',
    ]),
  ].join(',');

  const params = {
    ...data,
    selected_time_filter: 'predefined',
    translate_to: null,
    translate_all: false,
    refresh_rate: getUserToken() ? 'minutes10' : '600000',
    premium_content_only: false,
  };

  if (accessLevels.includes(SILVER)) {
    params.sentiment_filter = 'categories';
    params.sentiment_categories = ['very_positive', 'positive', 'neutral', 'negative', 'very_negative'];
    params.sentiment_down_limit = -100;
    params.sentiment_up_limit = 100;
  }
  return params;
};

const truncateButtonTitle = (value) => (value?.length > 20 ? `${value?.slice(0, 20)}...` : value);

export const getFilterValues = (filterName, filterValue, accessLevels, isResetData) => {
  let val = String.Empty;
  let fValue = null;
  const resultArr = [];
  switch (filterName) {
    case 'refresh_rate':
      if (!refreshValuesConversion[filterValue]) {
        fValue = refreshValuesConversion[refreshRateConversion[filterValue]];
      } else {
        fValue = refreshValuesConversion[filterValue];
      }
      val = refreshValues.filter(
        (refValue) => refValue.value
          === fValue,
      );
      return truncateButtonTitle(val[0]?.name);
    case 'order_by':
      val = orderValues.filter((orderVal) => orderVal.value === filterValue);
      return truncateButtonTitle(val[0]?.name);
    case 'time_filter':
      val = periodValues.filter((periodVal) => periodVal.value === filterValue);
      return truncateButtonTitle(val[0]?.name);
    case 'categories':
      if (filterValue === '') {
        return 'Select Sources';
      }
      val = sourceValues(filterValue, getUserToken(), isResetData);
      val.forEach((item) => {
        if (item.select) resultArr.push(item.name);
        item.sub.forEach((subItem) => {
          if (subItem.select && !resultArr.includes('Tweets')) resultArr.push(subItem.name);
        });
      });
      if (accessLevels.includes(TWEETS)) {
        return ((resultArr.length === 3 && resultArr.includes('Tweets')) ? truncateButtonTitle('All Sources') : truncateButtonTitle(resultArr.join(',')));
      }
      return ((resultArr.length === 2) ? truncateButtonTitle('All Sources') : truncateButtonTitle(resultArr.join(',')));

    case 'twitter_following_only':
      return filterValue ? 'ON' : 'OFF';
    case 'with_links':
      return filterValue ? 'ON' : 'OFF';
    case 'min_score':
      return `>=${filterValue}%`;
    case 'languages':
      if (filterValue === '') {
        return 'Select Languages';
      }
      return languagesCodes?.split(',')?.length === filterValue?.split(',')?.length ? 'All Languages' : truncateButtonTitle(filterValue)?.toUpperCase();
    case 'user_newsfeed_advanced_view_web':
    case 'user_newsfeed_advanced_view':
      return filterValue ? 'ON' : 'OFF';
    case 'user_newsfeed_view_web':
      return filterValue;
    case 'user_newsfeed_view':
      return filterValue;
    case 'show_similar_stories_in_feed':
      return filterValue ? 'ON' : 'OFF';
    case 'group_similar_stories':
      return filterValue ? 'ON' : 'OFF';
    case 'selected_time_filter':
      return filterValue?.toLowerCase() === 'predefined' ? '1 Month' : filterValue;
    case 'summarization_enabled':
      return filterValue ? 'ON' : 'OFF';
    case 'user_newsfeed_compactview_web':
      return filterValue ? 'compact' : 'row';
    case 'user_newsfeed_compactview':
      return filterValue ? 'compact' : 'tiles';
    case 'expand_stories':
      return filterValue ? 'ON' : 'OFF';
    case 'user_newsfeed_view_images_web':
      return filterValue ? 'ON' : 'OFF';
    case 'user_newsfeed_view_description_web':
      return filterValue ? 'ON' : 'OFF';
    case 'sentiment_categories':
      if (isEmpty(filterValue)) {
        return 'Select Sentiment';
      }
      sentimentValues?.filter((sentimentVal) => filterValue?.includes(sentimentVal?.value)
        && resultArr.push(sentimentVal.name));
      return resultArr.length === 5 ? truncateButtonTitle('All Sentiments') : truncateButtonTitle(resultArr.join(','));
    case 'sentiment_down_limit':
      return filterValue;
    case 'sentiment_up_limit':
      return filterValue;
    case 'registrationRequired':
      return filterValue ? 'ON' : 'OFF';
    case 'paywall':
      return filterValue ? 'ON' : 'OFF';
    case 'major_sources_only':
      return filterValue ? 'ON' : 'OFF';
    case 'premium_content_only':
      return filterValue ? 'ON' : 'OFF';
    case 'sentiment_filter':
      return filterValue;
    case 'isPremiumLabelsEnabled':
      return filterValue ? 'ON' : 'OFF';
    default:
      break;
  }
};
export const getAPICachingTime = (value) => moment().add(value, 's').toDate().getTime();

export const returnAPICachingData = (cachesObj, key, params) => {
  const cachedItems = (cachesObj[key] || []).find((cache) => isEqual(cache.params, params));
  if (cachedItems && cachedItems.expDate > Date.now()) {
    return cachedItems.response;
  }
  return null;
};

export const elementInViewStats = (element, root = null) => (
  new Promise((resolve) => {
    const observer = new window.IntersectionObserver(([entry]) => {
      resolve({
        visible: entry.isIntersecting,
        visibilityPercent: entry.intersectionRatio * 100,
        extra: entry,
      });
      observer.disconnect();
    }, {
      root,
      threshold: 0,
    });
    observer.observe(element);
  })
);

export const returnAPICachingHeader = (maxAge, staleValue = 900) => `max-age=${maxAge}, stale-while-revalidate=${staleValue}, stale-if-error=${staleValue}`;

export const returnCDNFoxyImageUrl = ({
  isFoxy,
  isCdn,
  imageURL,
  width = 500,
  height,
}) => {
  if (isCdn) {
    const optionsArr = [`width=${width || 500}`];
    if (height) {
      optionsArr.push(`height=${height}`);
    }
    return `/cdn-cgi/image/${optionsArr.join(',')}/${imageURL}`;
  }
  if (isFoxy) {
    return `https://foxy.cityfalcon.com/?url=${imageURL}`;
  }
  return imageURL;
};

export const checkFlexGapSupport = () => {
  // create flex container with row-gap set
  const flex = document.createElement('div');
  flex.style.display = 'flex';
  flex.style.flexDirection = 'column';
  flex.style.rowGap = '1px';

  // create two, elements inside it
  flex.appendChild(document.createElement('div'));
  flex.appendChild(document.createElement('div'));

  // append to the DOM (needed to obtain scrollHeight)
  document.body.appendChild(flex);
  const isSupported = flex.scrollHeight === 1;
  // flex container should be 1px high from the row-gap
  flex.parentNode.removeChild(flex);

  return isSupported;
};

export const sortAndOrderInfoLabelItems = (items = [], slugName) => {
  const infoLabelItemsPriorityOrder = {
    topic: 1,
    assetclass: 2,
    startupcategory: 3,
    area: 4,
    place: 5,
    city: 6,
    state: 7,
    country: 8,
    subcontinent: 9,
    continent: 10,
  };
  if (!items?.length) {
    return [];
  }

  const filteredItems = items
    .filter((item) => Object.keys(infoLabelItemsPriorityOrder)
      .includes(item?.entity_type.toLowerCase()));
  // sort tags based on priority order
  let infoLabelItems = filteredItems
    ?.sort((item1, item2) => infoLabelItemsPriorityOrder[item1.entity_type.toLowerCase()]
      - infoLabelItemsPriorityOrder[item2.entity_type.toLowerCase()]);
  // sort tags according to cf score.
  infoLabelItems = infoLabelItems.sort((item1, item2) => item2.cf_score - item1.cf_score);
  if (slugName) {
    infoLabelItems = infoLabelItems
      ?.sort((item1, item2) => (item1.entity_name.toLowerCase()
        === slugName?.toLowerCase() ? -1 : item2.entity_name.toLowerCase() === slugName?.toLowerCase() ? 1 : 0));
  }
  return infoLabelItems;
};
export function calculateLocaleTimeAgo(lang, time) {
  let relativeTime = {};

  switch (lang) {
    case 'en':
      relativeTime = {
        s: (number) => (number > 1 ? `${number} seconds ago` : `${number} second ago`),
        ss: '%d seconds ago',
        m: '1 minute ago',
        mm: '%d minutes ago',
        h: '1 hour ago',
        hh: '%d hours ago',
        d: '1 day ago',
        dd: '%d days ago',
        M: '1 month ago',
        MM: '%d months ago',
        y: '1 year ago',
        yy: '%d years ago',
      };
      break;
    case 'es':
      relativeTime = {
        s: (number) => (number > 1 ? `Hace ${number} segundos` : `Hace ${number} segundo`),
        ss: 'Hace %d segundos',
        m: 'Hace 1 minuto',
        mm: 'hace %d minutos',
        h: '1 hora antes',
        hh: 'Hace %d horas',
        d: 'Hace 1 día',
        dd: 'hace %d días',
        M: 'Hace 1 mes',
        MM: 'Hace %d meses',
        y: 'Hace 1 año',
        yy: 'Hace %d años',
      };
      break;
    case 'ru':
      relativeTime = {
        s: (number) => (number > 1 ? `${number} секунды назад` : `${number} секунду назад`),
        ss: (number) => {
          if (number < 2) {
            return `${number} секунду назад`;
          }
          if ((number < 5) || (number > 20 && (number % 10 >= 2 && number % 10 < 5))) {
            return `${number} секунды назад`;
          }
          if ((number >= 5 && number <= 20)
            || (number >= 25 && (number % 10 === 0 || (number % 10 >= 5 && number % 10 < 10)))) {
            return `${number} секунд назад`;
          }
          if (number > 20 && number % 10 === 1) {
            return `${number} секунду назад`;
          }
          return `${number} секунды назад`;
        },
        m: '1 минута назад',
        mm: (number) => {
          if (number < 2) {
            return `${number} минута назад`;
          }
          if ((number < 5) || (number > 20 && (number % 10 >= 2 && number % 10 < 5))) {
            return `${number} минуты назад`;
          }
          if ((number >= 5 && number <= 20)
            || (number >= 25 && (number % 10 === 0 || (number % 10 >= 5 && number % 10 < 10)))) {
            return `${number} минут назад`;
          }
          if (number > 20 && number % 10 === 1) {
            return `${number} минута назад`;
          }
          return `${number} минуты назад`;
        },
        h: '1 час назад',
        hh: (number) => {
          if (number < 2) {
            return `${number} час назад`;
          }
          if ((number < 5) || (number > 20 && (number % 10 >= 2 && number % 10 < 5))) {
            return `${number} часа назад`;
          }
          if ((number >= 5 && number <= 20)) {
            return `${number} часов назад`;
          }
          if (number > 20 && number % 10 === 1) {
            return `${number} час назад`;
          }
          return `${number} часа назад`;
        },
        d: '1 день назад',
        dd: (number) => {
          if (number < 2) {
            return `${number} день назад`;
          }
          if ((number < 5) || (number > 20 && (number % 10 >= 2 && number % 10 < 5))) {
            return `${number} дня назад`;
          }
          if ((number >= 5 && number <= 20)
            || (number >= 25 && (number % 10 === 0 || (number % 10 >= 5 && number % 10 < 10)))) {
            return `${number} дней назад`;
          }
          if (number > 20 && number % 10 === 1) {
            return `${number} день назад`;
          }
          return `${number} дня назад`;
        },
        M: '1 месяц назад',
        MM: (number) => {
          if (number < 2) {
            return `${number} месяц назад`;
          }
          if (number >= 2 && number < 5) {
            return `${number} месяца назад`;
          }
          if (number >= 5) {
            return `${number} месяцев назад`;
          }
          return `${number} месяцев назад`;
        },
        y: '1 год назад',
        yy: (number) => {
          if (number < 2) {
            return `${number} год назад`;
          }
          if ((number < 5) || (number > 20 && (number % 10 >= 2 && number % 10 < 5))) {
            return `${number} года назад`;
          }
          if ((number >= 5 && number <= 20)
            || (number >= 25 && (number % 10 === 0 || (number % 10 >= 5 && number % 10 < 10)))) {
            return `${number} лет назад`;
          }
          if (number > 20 && number % 10 === 1) {
            return `${number} год назад`;
          }
          return `${number} года назад`;
        },
      };
      break;
    case 'pl':
      relativeTime = {
        s: (number) => (number > 1 ? `${number} sekund temu` : `${number} sekunda temu`),
        ss: (number) => {
          if (number < 2) {
            return `${number} sekunda temu`;
          }
          if ((number < 5) || (number > 20 && (number % 10 >= 2 && number % 10 < 5))) {
            return `${number} sekundy temu`;
          }
          if ((number >= 5 && number <= 20)
            || (number >= 25 && (number % 10 === 0 || (number % 10 >= 5 && number % 10 < 10)))) {
            return `${number} sekund temu`;
          }
          if (number > 20 && number % 10 === 1) {
            return `${number} sekund temu`;
          }
          return `${number} sekund temu`;
        },
        m: '1 minuta temu',
        mm: (number) => {
          if (number < 2) {
            return `${number} minuta temu`;
          }
          if ((number < 5) || (number > 20 && (number % 10 >= 2 && number % 10 < 5))) {
            return `${number} minuty temu`;
          }
          if ((number >= 5 && number <= 20)
            || (number >= 25 && (number % 10 === 0 || (number % 10 >= 5 && number % 10 < 10)))) {
            return `${number} minut temu`;
          }
          if (number > 20 && number % 10 === 1) {
            return `${number} minut temu`;
          }
          return `${number} minut temu`;
        },
        h: '1 godzinę temu',
        hh: (number) => {
          if (number < 2) {
            return `${number} godzinę temu`;
          }
          if ((number < 5) || (number > 20 && (number % 10 >= 2 && number % 10 < 5))) {
            return `${number} godziny temu`;
          }
          if ((number >= 5 && number <= 20)) {
            return `${number} godzin temu`;
          }
          if (number > 20 && number % 10 === 1) {
            return `${number} godzin temu`;
          }
          return `${number} godziny temu`;
        },
        d: '1 dzień temu',
        dd: '%d dni temu',
        M: '1 miesiąc temu',
        MM: (number) => {
          if (number < 2) {
            return `${number} miesiąc temu`;
          }
          if (number >= 2 && number < 5) {
            return `${number} miesiące temu`;
          }
          if (number >= 5) {
            return `${number} miesięcy temu`;
          }
          return `${number} miesięcy temu`;
        },
        y: '1 rok temu',
        yy: (number) => {
          if (number < 2 || number === 4) {
            return `${number} rok temu`;
          }
          if (number >= 2 && number < 4) {
            return `${number} lata temu`;
          }
          if ((number > 5) && ((number < 20 && number % 10 !== 4) || number % 10 !== 1)) {
            return `${number} lat temu`;
          }
          if (number > 20 && number % 10 === 1) {
            return `${number} rok temu`;
          }
          return `${number} lat temu`;
        },
      };
      break;
    case 'zh-cn':
      relativeTime = {
        s: (number) => (number > 1 ? `${number} 秒前` : `${number} 秒前`),
        ss: '%d 秒前',
        m: '1 分钟前',
        mm: '%d 分钟前',
        h: '1 小时前',
        hh: '%d 小时前',
        d: '1 天前',
        dd: '%d 天前',
        M: '1 个月前',
        MM: '%d 个月前',
        y: '1 年前',
        yy: '%d 年前',
      };
      break;
    case 'zh-tw':
      relativeTime = {
        s: (number) => (number > 1 ? `${number} 秒前` : `${number} 秒前`),
        ss: '%d 秒前',
        m: '1 分鐘前',
        mm: '%d 分鐘前',
        h: '1 小時前',
        hh: '%d 小時前',
        d: '1 天前',
        dd: '%d 天前',
        M: '1 個月前',
        MM: '%d 個月前',
        y: '1 年前',
        yy: '%d 年前',
      };
      break;
    case 'fr':
      relativeTime = {
        s: (number) => (number > 1 ? `Il y a ${number} secondes` : `Il y a ${number} seconde`),
        ss: 'Il y a %d secondes',
        m: 'il y a 1 minute',
        mm: 'Il y a %d minutes',
        h: 'Il ya 1 heure',
        hh: 'il y a %d heures',
        d: 'il y a 1 jour',
        dd: 'il y a %d jours',
        M: 'Il ya 1 mois',
        MM: 'il y a %d mois',
        y: 'il y a 1 an',
        yy: 'Il y a %d ans',
      };
      break;
    case 'ar':
      relativeTime = {
        s: (number) => (number > 1 ? `ثوان ${number} منذ` : 'قبل ثانية واحدة'),
        ss: 'ثوان %d منذ` ',
        m: 'منذ 1 دقيقة',
        mm: 'منذ %d دقيقة',
        h: 'منذ 1 ساعة',
        hh: 'قبل %d ساعات',
        d: '1 منذ يوم',
        dd: (number) => {
          if (number < 2) {
            return `${number} منذ يوم`;
          }
          if (number === 2) {
            return `${number} منذ أيام`;
          }
          if (number >= 3 && number <= 10) {
            return `منذ ${number} أيام`;
          }
          if (number > 10) {
            return `منذ ${number} يومًا`;
          }
          return `منذ ${number} يومًا`;
        },
        M: '1 قبل شهر',
        MM: 'قبل %d أشهر',
        y: 'منذ سنة 1',
        yy: 'منذ %d سنوات',
      };
      break;
    case 'cz':
      relativeTime = {
        s: (number) => (number > 1 ? `Před  ${number} sekundami` : `Před ${number} sekundou`),
        ss: 'Před  %d sekundami',
        m: 'Před 1 minutou',
        mm: 'Před %d minutami',
        h: 'Před 1 hodinou',
        hh: 'Před %d hodinami',
        d: 'Před 1 dnem',
        dd: (number) => {
          if (number < 2) {
            return `Před ${number} dnem`;
          }
          if ((number >= 2) && number !== 4) {
            return ` Před ${number} dny`;
          }
          if ((number === 4)) {
            return '4 dny zpátky';
          }
          return ` Před ${number} dny`;
        },
        M: 'Před 1 měsícem',
        MM: 'Před % měsíci',
        y: 'Před 1 rokem',
        yy: (number) => {
          if (number === 3 || number === 5 || number === 8 || number % 10 === 0) {
            return `Před ${number} lety`;
          }
          return ` Před ${number} rokem`;
        },
      };
      break;
    case 'dk':
      relativeTime = {
        s: (number) => (number > 1 ? `${number} sekunder siden` : `${number} sekund siden`),
        ss: '%d sekunder siden',
        m: '1 minut siden',
        mm: '%d minutter siden',
        h: '1 time siden',
        hh: '%d timer siden',
        d: '1 dag siden',
        dd: '%d dage siden',
        M: '1 måned siden',
        MM: '%d måneder siden',
        y: '1 år siden',
        yy: '%d år siden',
      };
      break;
    case 'de':
      relativeTime = {
        s: (number) => (number > 1 ? `Vor ${number} Sekunden` : `Vor ${number} Sekunde`),
        ss: 'Vor %d Sekunden',
        m: 'Vor 1 Minute',
        mm: 'Vor %d Minuten',
        h: 'Vor 1 Stunde',
        hh: 'Vor %d Stunden',
        d: 'Vor 1 Tag',
        dd: 'Vor %d Tagen',
        M: 'Vor 1 Monat',
        MM: 'Vor %d Monaten',
        y: 'vor 1 Jahr',
        yy: 'vor %d Jahren',
      };
      break;
    case 'fi':
      relativeTime = {
        s: (number) => (number > 1 ? `${number} sekuntia sitten` : `${number} sekunti sitten`),
        ss: '%d sekuntia sitten',
        m: '1 minuutti sitten',
        mm: '2 minuuttia sitten',
        h: '1 tunti sitten',
        hh: '%d tuntia sitten',
        d: '1 päivä sitten',
        dd: '%d päivää sitten',
        M: '1 kuukausi sitten',
        MM: '%d kuukautta sitten',
        y: '1 vuosi sitten',
        yy: '%d vuotta sitten',
      };
      break;
    case 'no':
      relativeTime = {
        s: (number) => (number > 1 ? `${number} sekunder siden` : `${number} for andre siden`),
        ss: '%d sekunder siden',
        m: '1 minuto fa',
        mm: '%d minutter siden',
        h: '1 time siden',
        hh: '%d timer siden',
        d: '1 dag siden',
        dd: '%d dager siden',
        M: '1 måned siden',
        MM: '%d måneder siden',
        y: '1 år siden',
        yy: '%d år siden',
      };
      break;
    case 'nl':
      relativeTime = {
        s: (number) => (number > 1 ? `${number} seconden geleden` : `${number} tweede geleden`),
        ss: '%d seconden geleden',
        m: '1 minuut geleden',
        mm: '%d minuten geleden',
        h: '1 uur geleden',
        hh: '%d uur geleden',
        d: '1 dag geleden',
        dd: '%d dagen geleden',
        M: '1 maand geleden',
        MM: '%d maanden geleden',
        y: '1 jaar geleden',
        yy: '%d jaar geleden',
      };
      break;
    case 'it':
      relativeTime = {
        s: (number) => (number > 1 ? `${number} secondi fa` : `${number} secondo`),
        ss: '%d secondi fa',
        m: '1 minuto fa',
        mm: '%d minuti fa',
        h: '1 ora fa',
        hh: '%d ore fa',
        d: '1 giorno fa',
        dd: '%d giorni fa',
        M: '1 mese fa',
        MM: '%d mesi fa',
        y: '1 anno fa',
        yy: '%d anni fa',
      };
      break;
    case 'pt':
      relativeTime = {
        s: (number) => (number > 1 ? `${number} segundos atrás` : `${number} segundo atrás`),
        ss: '%d segundos atrás',
        m: 'Há 1 minuto',
        mm: (number) => {
          if (number === 15) {
            return 'Há 15 minutos';
          }
          return `${number} minutos atrás`;
        },
        h: '1 hora atrás',
        hh: '%d horas atrás',
        d: '1 dia atrás',
        dd: '2 dias atrás',
        M: '1 mês atrás',
        MM: (number) => {
          if (number === 4) {
            return 'Há 4 meses';
          }
          return `${number} meses atrás`;
        },
        y: '1 ano atrás',
        yy: '%d anos atrás',
      };
      break;
    case 'ro':
      relativeTime = {
        s: (number) => (number > 1 ? `Acum ${number} secunde` : `Acum ${number} secundă`),
        ss: (number) => {
          if (number >= 20) {
            return `Acum ${number} de secunde`;
          }
          return `Acum ${number} secunde`;
        },
        m: 'Acum 1 minut',
        mm: (number) => {
          if (number >= 20) {
            return `Acum ${number} de minute`;
          }
          return `Acum ${number} minute`;
        },
        h: '1 oră în urmă',
        hh: (number) => {
          if (number <= 2) {
            return `${number} oră în urmă`;
          }
          if (number >= 20) {
            return `Acum ${number} de ore`;
          }
          return `Acum ${number} ore`;
        },
        d: 'Acum 1 zi',
        dd: (number) => {
          if (number === 2) {
            return '2 zile în urmă';
          }
          return `Acum ${number} zile`;
        },
        M: 'Acum 1 lună',
        MM: (number) => {
          if (number === 2) {
            return `${number} luni în urmă`;
          }
          return `Acum ${number} luni`;
        },
        y: '1 an in urma',
        yy: (number) => {
          if (number >= 20) {
            return `Acum ${number} de ani`;
          }
          return `Acum ${number} ani`;
        },
      };
      break;
    case 'se':
      relativeTime = {
        s: (number) => (number > 1 ? `${number} sekunder sedan` : 'För en sekund sedan'),
        ss: '%d sekunder sedan',
        m: '1 minut sedan',
        mm: '%d minuter sedan',
        h: '1 timme sedan',
        hh: '%d timmar sedan',
        d: '1 dag sedan',
        dd: (number) => {
          if (number === 5 || number === 6) {
            return `${number} dagar sen`;
          }
          return `${number} dagar sedan`;
        },
        M: '1 månad sedan',
        MM: '%d månader sedan',
        y: '1 år sedan',
        yy: '%d år sedan',
      };
      break;
    case 'vi':
      relativeTime = {
        s: (number) => (number > 1 ? `${number} giây trước` : `${number} giây trước`),
        ss: '%d giây trước',
        m: '1 phút trước',
        mm: '%d phút trước',
        h: '1 giờ trước',
        hh: '%d giờ trước',
        d: '1 ngày trước',
        dd: '%d ngày trước',
        M: '1 tháng trước',
        MM: '%d tháng trước',
        y: '1 năm trước',
        yy: '%d năm trước',
      };
      break;
    default:
      relativeTime = {
        s: (number) => (number > 1 ? `${number} seconds ago` : `${number} second ago`),
        ss: '%d seconds ago',
        m: '1 minute ago',
        mm: '%d minutes ago',
        h: '1 hour ago',
        hh: '%d hours ago',
        d: '1 day ago',
        dd: '%d days ago',
        M: '1 month ago',
        MM: '%d months ago',
        y: '1 year ago',
        yy: '%d years ago',
      };
  }

  return moment(time).fromNow(
    moment.updateLocale(lang, {
      relativeTime,
    }),
  );
}

export const getLocaleMonths = (lang) => {
  let month;
  switch (lang) {
    case 'en':
      month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug',
        'Sep', 'Oct', 'Nov', 'Dec'];
      break;
    case 'es':
      month = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
      break;
    case 'ru':
      month = [
        'Янв',
        'Февр',
        'Мар',
        'Апр',
        'Май',
        'Июнь',
        'Июль',
        'Авг',
        'Сен',
        'Окт',
        'Нояб',
        'Дек'];
      break;
    case 'pl':
      month = [
        'Styczeń',
        'Luty',
        'Marzec',
        'Kwiecień',
        'Maj',
        'Czerwiec',
        'Lipiec',
        'Sierpień',
        'Wrzesień',
        'Październik',
        'Listopad',
        'Grudzień'];
      break;
    case 'zh-cn':
      month = ['一月', '二月', '三月', '四月', '五月 ', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];
      break;
    case 'zh-tw':
      month = ['一月', '二月', '三月', '四月', '五月 ', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];
      break;
    case 'fr':
      month = ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'];
      break;
    case 'ar':
      month = ['يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', 'يونيو', 'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'];
      break;
    case 'cz':
      month = ['led.', 'úno.', 'bře.', 'dub.', 'kvě.', 'čvn.', 'čvc.', 'srp.', 'zář.', 'říj.', 'lis.', 'pro.'];
      break;
    case 'dk':
      month = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];
      break;
    case 'de':
      month = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'Mai.', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Okt.', 'Nov.', 'Dez.'];
      break;
    case 'fi':
      month = ['tammikuu', 'helmikuu', 'maaliskuu', 'huhtikuu', 'toukokuu', 'kesäkuu', 'heinäkuu', 'elokuu', 'syyskuu', 'lokakuu', 'marraskuu', 'joulukuu'];
      break;
    case 'it':
      month = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
      break;
    case 'no':
      month = ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'];
      break;
    case 'nl':
      month = ['Jan', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];
      break;
    case 'pt':
      month = ['Jan.', 'Fev.', 'Mar.', 'Abr.', 'Mai.', 'Jun.', 'Jul.', 'Ago.', 'Set.', 'Out.', 'Nov.', 'Dez.'];
      break;
    case 'ro':
      month = ['Ian', 'Feb', 'Mar', 'Apr', 'Mai', 'Iun', 'Iul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
      break;
    case 'se':
      month = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];
      break;
    case 'vi':
      month = ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'];
      break;

    default:
      month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug',
        'Sep', 'Oct', 'Nov', 'Dec'];
  }
  return month;
};

export const getCFSupportedLanguages = (languages) => {
  const lang = languages?.split(',');
  const selectedLanguages = [];

  lang?.forEach((lang) => {
    switch (lang.toLowerCase()) {
      case 'en-gb':
      case 'en-us':
      case 'en-en':
      case 'en':
        selectedLanguages.push('en');
        break;
      case 'es-es':
      case 'es':
        selectedLanguages.push('es');
        break;
      case 'it-it':
      case 'it':
        selectedLanguages.push('it');
        break;
      case 'de-de':
      case 'de':
        selectedLanguages.push('de');
        break;
      case 'ru-ru':
      case 'ru':
        selectedLanguages.push('ru');
        break;
      case 'zh-cn':
      case 'zh-tw':
      case 'zh':
        selectedLanguages.push('zh');
        break;
      case 'fr-fr':
      case 'fr':
        selectedLanguages.push('fr');
        break;
      case 'ar-ae':
      case 'ar':
        selectedLanguages.push('ar');
        break;
      case 'pl-pl':
      case 'pl':
        selectedLanguages.push('pl');
        break;
      case 'nl-nl':
      case 'nl':
        selectedLanguages.push('nl');
        break;
      case 'nb-no':
      case 'no':
        selectedLanguages.push('no');
        break;
      case 'pt-pt':
      case 'pt':
        selectedLanguages.push('pt');
        break;
      case 'sv-se':
      case 'se':
        selectedLanguages.push('se');
        break;
      case 'cs-cz':
      case 'cz':
        selectedLanguages.push('cz');
        break;
      case 'da-dk':
      case 'dk':
        selectedLanguages.push('dk');
        break;
      case 'ro-ro':
      case 'ro':
        selectedLanguages.push('ro');
        break;
      case 'vi-vn':
      case 'vi':
        selectedLanguages.push('vi');
        break;
      case 'fi-fi':
      case 'fi':
        selectedLanguages.push('fi');
        break;
      case 'uk':
        selectedLanguages.push('uk');
        break;
      case 'cs':
        selectedLanguages.push('cs');
        break;
      case 'sl':
        selectedLanguages.push('sl');
        break;
      case 'sk':
        selectedLanguages.push('sk');
        break;
      case 'cnr':
        selectedLanguages.push('cnr');
        break;
      case 'bs':
        selectedLanguages.push('bs');
        break;
      case 'bg':
        selectedLanguages.push('bg');
        break;
      case 'be':
        selectedLanguages.push('be');
        break;
      case 'mk':
        selectedLanguages.push('mk');
        break;
      case 'sr':
        selectedLanguages.push('sr');
        break;
      case 'hr':
        selectedLanguages.push('hr');
        break;
      case 'lv':
        selectedLanguages.push('lv');
        break;
      case 'lt':
        selectedLanguages.push('lt');
        break;
      default:
        selectedLanguages.push(lang);
        break;
    }
  });
  const cfSupportedLanguages = selectedLanguages.filter((lng) => CF_NLU2_LANGS?.split(',').includes(lng));
  return cfSupportedLanguages;
};

export const getAppLanguage = (language = 'en') => {
  const languageCodesMapping = new Map([
    ['en-gb', 'en'],
    ['en-us', 'en'],
    ['en', 'en'],
    ['es-es', 'es'],
    ['es', 'es'],
    ['it-it', 'it'],
    ['it', 'it'],
    ['de-de', 'de'],
    ['de', 'de'],
    ['ru-ru', 'ru'],
    ['ru', 'ru'],
    ['zh-cn', 'zh-cn'],
    ['zh', 'zh-cn'],
    ['fr-fr', 'fr'],
    ['fr', 'fr'],
    ['ar-ae', 'ar'],
    ['ar', 'ar'],
    ['pl-pl', 'pl'],
    ['pl', 'pl'],
    ['nl-nl', 'nl'],
    ['nl', 'nl'],
    ['nb-no', 'no'],
    ['no', 'no'],
    ['pt-pt', 'pt'],
    ['pt', 'pt'],
    ['sv-se', 'se'],
    ['se', 'se'],
    ['cs-cz', 'cz'],
    ['cz', 'cz'],
    ['da-dk', 'dk'],
    ['dk', 'dk'],
    ['ro-ro', 'ro'],
    ['ro', 'ro'],
    ['vi-vn', 'vi'],
    ['vi', 'vi'],
    ['fi-fi', 'fi'],
    ['fi', 'fi'],
    ['zh-tw', 'zh-tw'],
  ]);

  return languageCodesMapping.get(language) ?? 'en';
};
